.row {
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 0px;
  height: 100%;
  border-bottom: 1px solid var(--color-bg-border);
  padding: 20px 0;
  &:last-of-type {
    border-bottom: none;
  }
}

.network_name {
  grid-row-start: 1;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-column-end: 2;

  font-size: 16px;
  line-height: 100%;
  font-weight: normal;

  color: var(--color-typo-primary);

  margin: 0;

  display: flex;
  flex-direction: row;
}

.nodes_list {
  grid-row-start: 1;
  grid-column-start: 2;
  grid-row-end: 2;
  grid-column-end: 6;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px;
}

.card {
  padding: 16px;
  border: 1px solid var(--color-bg-border);
  border-radius: 6px;
  //   background-color: #000;
  &_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    & > div:first-child {
      font-size: 16px;
      font-weight: bold;
      color: var(--color-typo-primary);
    }
  }
  &_data__block {
    font-size: 12px;

    .client {
      display: flex;
      flex-direction: column;
      color: var(--color-typo-secondary);
      .kek {
        margin-bottom: 8px;
      }
      & > div > span {
        margin-bottom: 4px;
      }
    }
    .metadata_wrapper {
      margin-top: 12px;
      display: flex;
      .metadata {
        flex: 50%;
        display: flex;
        flex-direction: column;
        & > span {
          margin-bottom: 8px;
          color: var(--color-typo-secondary);
          font-size: 12px;
        }
        & > div {
          width: fit-content;
          color: var(--color-typo-primary);
          font-size: 16px;
        }
        .size_value {
          font-weight: bold;
          .custom_tag {
            background-color: var(--color-typo-brand);
            color: #fff;
          }
        }
      }
    }
  }
}

.spinner {
  color: var(--color-typo-primary);
}

@media only screen and (max-width: 600px) {
  .row {
    display: block;
    border: none;
    &:first-of-type {
      padding-top: 0px;
    }
    &:last-of-type {
      padding-bottom: 0px;
    }
  }

  .nodes_list {
    display: block;
  }

  .network_name {
    font-size: 32px;
    margin-bottom: 12px;
    font-weight: 700;
  }

  .card {
    margin-bottom: 12px;
  }
}
