.header {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  &_left {
    display: flex;
    align-items: center;
    font-style: normal;
    line-height: 100%;
    &__labels {
      margin-left: 12px;
      .name {
        font-weight: 600;
        font-size: 20px;
        line-height: 100%;
        margin: 0 0 6px 0;
        color: var(--color-typo-brand);
      }
      .description {
        font-weight: 500;
        line-height: 100%;
        font-size: 12px;
        color: var(--color-typo-secondary);
        margin: 0;
      }
    }
  }
  &_right {
    display: flex;
    align-items: center;
    & > :not(:last-child) {
      margin-right: 8px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .header {
    display: block;
    // flex-direction: column;
    &_left {
      align-items: flex-start;
      margin-bottom: 24px;
      &__labels {
        margin-left: 12px;
        .name {
          font-size: 24px;
          line-height: 100%;

          margin-bottom: 0 0 12px 0;
        }
        .description {
          font-size: 14px;
          line-height: 120%;
          margin: 0;
        }
      }
    }
    &_right {
      display: block;
      // align-items: center;
      // flex-direction: column;
      & > :not(:last-child) {
        margin-right: 8px;
      }
      & > :last-child {
        display: none;
      }
    }
  }
}
