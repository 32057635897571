.content {
  background-color: var(--color-bg-primary);
  margin: 0 20px;
  border-radius: 20px;
  height: calc(100vh - 88px - 20px);
  max-width: 700px;
  margin: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.app {
  background-color: var(--color-bg-secondary);
  height: 100vh;
}

@media only screen and (max-width: 600px) {
  .content {
    height: auto;
    overflow: auto;
    border-radius: 10px;
  }

  .app {
    height: auto;
  }
}
