.layoutWrapper {
  &_loading {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--color-typo-primary);
  }
  &_contentWrapper {
    padding: 32px;
    margin: auto;
    max-width: 1400px;
    overflow-x: hidden;
    .search_input {
      margin-bottom: 20px;
    }
  }
  &_no-results {
    text-align: center;
    line-height: 140%;
    color: var(--color-typo-primary);
  }
}

@media only screen and (max-width: 600px) {
  .layoutWrapper {
    &_loading {
      //   display: flex;
      //   justify-content: space-around;
      //   align-items: center;
      //   color: var(--color-typo-primary);
    }
    &_contentWrapper {
      padding: 12px;
      padding-bottom: 32px;
    }
  }
}
